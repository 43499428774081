import { tokens } from "../tokens"

// ----------------------------------------------------------------------

const br = {
  [tokens.alerts.attention]: 'Atenção',
  [tokens.alerts.error]: 'Erro',
  [tokens.alerts.success]: 'Sucesso',
  [tokens.alerts.warning]: 'Cuidado',
  [tokens.buttons.accept]: 'Aceitar',
  [tokens.buttons.add]: 'Adicionar',
  [tokens.buttons.addTo]: 'Adicionar ao',
  [tokens.buttons.addToAlt]: 'Adicionar a',
  [tokens.buttons.back]: 'Voltar',
  [tokens.buttons.bulkEdit]: 'Edição em massa',
  [tokens.buttons.bulkStockEntry]: 'Entrada de estoque em massa',
  [tokens.buttons.cancel]: 'Cancelar',
  [tokens.buttons.change]: 'Alterar',
  [tokens.buttons.clearFilter]: 'Limpar filtros',
  [tokens.buttons.create]: 'Criar',
  [tokens.buttons.confirm]: 'Confirmar',
  [tokens.buttons.delete]: 'Excluir',
  [tokens.buttons.deliver]: 'Entregar ao cliente',
  [tokens.buttons.edit]: 'Editar',
  [tokens.buttons.filter]: 'Filtrar',
  [tokens.buttons.invite]: 'Convidar',
  [tokens.buttons.new]: 'Novo',
  [tokens.buttons.newAlt]: 'Nova',
  [tokens.buttons.reconnect]: 'Reconectar',
  [tokens.buttons.rememberAcc]: 'Lembrar conta',
  [tokens.buttons.record]: 'Gravar',
  [tokens.buttons.resendCodeEmail]: 'Reenviar código',
  [tokens.buttons.resendVerifEmail]: 'Reenviar e-mail de verificação',
  [tokens.buttons.prepare]: 'Preparar',
  [tokens.buttons.save]: 'Salvar',
  [tokens.buttons.select]: 'Selecionar',
  [tokens.buttons.setReady]: 'Pronto para retirar',
  [tokens.buttons.show]: 'Exibir',
  [tokens.buttons.submit]: 'Enviar',
  [tokens.buttons.sync]: 'Sincronizar',
  [tokens.buttons.update]: 'Atualizar',
  [tokens.buttons.upload]: 'Carregar',
  [tokens.buttons.verify]: 'Verificar',
  [tokens.buttons.view]: 'Visualizar',
  [tokens.calendar.day]: 'Dia',
  [tokens.calendar.week]: 'Semana',
  [tokens.calendar.month]: 'Mês',
  [tokens.calendar.year]: 'Ano',
  [tokens.charts.activeSale]: 'Promoções Ativas',
  [tokens.charts.averagePublic]: 'Média de Público',
  [tokens.charts.averageTicket]: 'Ticket Médio',
  [tokens.charts.monthlyComparison]: '{{ value }} que o mês passado',
  [tokens.charts.publicDistribution]: 'Distribuição de Público',
  [tokens.charts.publicAgeDistribution]: 'Faixa Etária de Público este',
  [tokens.charts.saleIncome]: 'Receita Promoções',
  [tokens.charts.salesOverview]: 'Visão Geral de Vendas',
  [tokens.charts.salesPerVenue]: 'Vendas por Estabelecimento',
  [tokens.charts.productSalesPerCategory]: 'Produtos Vendidos por Categoria',
  [tokens.charts.productSalesRanking]: 'Produtos Mais Vendidos',
  [tokens.charts.weeklyComparison]: '{{ value }} que a semana passada',
  [tokens.common.all]: 'Todos',
  [tokens.common.allowed]: 'Permitido',
  [tokens.common.authorized]: 'Autorizado',
  [tokens.common.browse]: 'Navegar',
  [tokens.common.bulk]: 'Em massa',
  [tokens.common.date]: 'Data',
  [tokens.common.entry]: 'Entrada',
  [tokens.common.exit]: 'Saída',
  [tokens.common.history]: 'Histórico',
  [tokens.common.languageChanged]: 'Idioma alterado',
  [tokens.common.maxSize]: 'Tamanho máximo',
  [tokens.common.notAllowed]: 'Não permitido',
  [tokens.common.notes]: 'Notas',
  [tokens.common.or]: 'ou',
  [tokens.common.question]: 'Dúvida',
  [tokens.common.recommended]: 'Recomendado',
  [tokens.common.selected]: 'Selecionado',
  [tokens.common.source]: 'Origem',
  [tokens.common.to]: 'para',
  [tokens.common.unauthorized]: 'Desautorizado',
  [tokens.common.with]: 'com',
  [tokens.components.fileUpload.header]: 'Arraste ou Selecione arquivo',
  [tokens.components.fileUpload.subStart]: 'Arraste aqui o arquivo ou clique para',
  [tokens.components.fileUpload.subEnd]: 'do seu computador',
  [tokens.components.filter]: 'Filtros',
  [tokens.components.search]: 'Buscar',
  [tokens.components.photo]: 'Foto',
  [tokens.controls.createdAt]: 'Criado em',
  [tokens.controls.createdBy]: 'Criado por',
  [tokens.controls.orderHistory]: 'Histórico do pedido',
  [tokens.controls.updatedAt]: 'Atualizado em',
  [tokens.controls.updatedBy]: 'Atualizado por',
  [tokens.fields.address]: 'Endereço',
  [tokens.fields.addressProof]: 'Comprovante de Endereço',
  [tokens.fields.amount]: 'Valor',
  [tokens.fields.area]: 'Área',
  [tokens.fields.bankingProof]: 'Comprovante Bancário',
  [tokens.fields.categoryCode]: 'Código da categoria',
  [tokens.fields.categorySku]: 'SKU da categoria',
  [tokens.fields.city]: 'Cidade',
  [tokens.fields.code]: 'Código',
  [tokens.fields.comment]: 'Comentário',
  [tokens.fields.complement]: 'Complemento',
  [tokens.fields.companyContract]: 'Contrato Social',
  [tokens.fields.cost]: 'Custo',
  [tokens.fields.country]: 'País',
  [tokens.fields.description]: 'Descrição',
  [tokens.fields.discount]: 'Desconto',
  [tokens.fields.districtCode]: 'Código Municipal',
  [tokens.fields.districtId]: 'Inscrição Municipal',
  [tokens.fields.drinks]: 'Bebidas',
  [tokens.fields.email]: 'E-mail',
  [tokens.fields.entranceFee]: 'Valor de Entrada',
  [tokens.fields.federalId]: 'CNPJ',
  [tokens.fields.federalIdCard]: 'Cartão CNPJ',
  [tokens.fields.firstName]: 'Nome',
  [tokens.fields.fiscalRegime]: 'Regime Tributário',
  [tokens.fields.from]: 'De',
  [tokens.fields.food]: 'Comidas',
  [tokens.fields.gender]: 'Gênero',
  [tokens.fields.lastName]: 'Sobrenome',
  [tokens.fields.legalName]: 'Razão Social',
  [tokens.fields.manager]: 'Responsável',
  [tokens.fields.minAmount]: 'Pedido Mínimo',
  [tokens.fields.name]: 'Nome',
  [tokens.fields.notVerified]: 'Não verificado',
  [tokens.fields.number]: 'Número',
  [tokens.fields.openStatus]: 'Funcionamento',
  [tokens.fields.password]: 'Senha',
  [tokens.fields.placeholders.description]: 'Breve descrição do seu estabelecimento que ficará disponível para os seus clientes...',
  [tokens.fields.phone]: 'Telefone',
  [tokens.fields.price]: 'Preço normal',
  [tokens.fields.productCode]: 'Código do produto',
  [tokens.fields.productNcm]: 'NCM do produto',
  [tokens.fields.productSku]: 'SKU do produto',
  [tokens.fields.quantity]: 'Quantidade',
  [tokens.fields.salePrice]: 'Preço promocional',
  [tokens.fields.sale]: 'Promoção',
  [tokens.fields.sku]: 'SKU',
  [tokens.fields.stock]: 'Estoque',
  [tokens.fields.stockQuantity]: 'Quantidade em estoque',
  [tokens.fields.stockThreshold]: 'Estoque mínimo',
  [tokens.fields.state]: 'Estado',
  [tokens.fields.stateId]: 'Inscrição Estadual',
  [tokens.fields.status]: 'Situação',
  [tokens.fields.street]: 'Rua',
  [tokens.fields.subtotal]: 'Subtotal',
  [tokens.fields.taxes]: 'Impostos',
  [tokens.fields.to]: 'Até',
  [tokens.fields.total]: 'Total',
  [tokens.fields.underVerification]: 'Em verificação',
  [tokens.fields.unitPrice]: 'Preço unitário',
  [tokens.fields.verified]: 'Verificado',
  [tokens.fields.venueType]: 'Segmento',
  [tokens.fields.weekday]: 'Dia da Semana',
  [tokens.fields.zipCode]: 'Código Postal',
  [tokens.gender.female]: 'Mulher',
  [tokens.gender.male]: 'Homem',
  [tokens.gender.unidentified]: 'Não identificado',
  [tokens.helper.invalid]: 'É inválido',
  [tokens.helper.invalidEmail]: 'O endereço de e-mail precisa ser válido',
  [tokens.helper.isRequired]: 'é obrigatório',
  [tokens.helper.minChar]: '{{ field }} precisa ter no mínimo {{ count }} caracteres',
  [tokens.helper.minLower]: '{{ field }} precisa ter no mínimo {{ count }} letra minúscula',
  [tokens.helper.minNumber]: '{{ field }} precisa ter no mínimo {{ count }} número',
  [tokens.helper.minSymbol]: '{{ field }} precisa ter no mínimo {{ count }} caracter especial',
  [tokens.helper.minUpper]: '{{ field }} precisa ter no mínimo {{ count }} letra maiúscula',
  [tokens.helper.noMatch]: `não são iguais`,
  [tokens.helper.noName]: '{{ field }} não pode conter {{ notAllowed }}',
  [tokens.helper.noSeqNumber]: '{{ field }} não pode conter números sequenciais',
  [tokens.helper.noSpace]: '{{ field }} não pode conter espaço',
  [tokens.kpi.ranking]: 'Posição',
  [tokens.kpi.totalIncome]: 'Receita Total',
  [tokens.nav.about]: 'Sobre nós',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Conta',
  [tokens.nav.activeOrders]: 'Pedidos Ativos',
  [tokens.nav.activeOrdersList]: 'Lista de Pedidos Ativos',
  [tokens.nav.app]: 'App',
  [tokens.nav.analytics]: 'Analítico',
  [tokens.nav.auth]: 'Autenticação',
  [tokens.nav.banking]: 'Bancário',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.booking]: 'Agendamento',
  [tokens.nav.brokers]: 'Corretores',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendário',
  [tokens.nav.canceledOrders]: 'Pedidos Cancelados',
  [tokens.nav.canceledOrdersList]: 'Lista de Pedidos Cancelados',
  [tokens.nav.cards]: 'Cartões',
  [tokens.nav.category]: 'Categoria',
  [tokens.nav.categories]: 'Categorias',
  [tokens.nav.categoryList]: 'Lista de Categorias',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Conceitos',
  [tokens.nav.contact]: 'Contato',
  [tokens.nav.course]: 'Curso',
  [tokens.nav.create]: 'Criar',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customer]: 'Cliente',
  [tokens.nav.customers]: 'Clientes',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Detalhes',
  [tokens.nav.docs]: 'Documentação',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.economicGroup]: 'Grupo Econômico',
  [tokens.nav.edit]: 'Editar',
  [tokens.nav.error]: 'Erro',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'Gestor de Arquivos',
  [tokens.nav.files]: 'Arquivos',
  [tokens.nav.finance]: 'Finanças',
  [tokens.nav.fiscal]: 'Fiscal',
  [tokens.nav.fleet]: 'Frota',
  [tokens.nav.forgotPassword]: 'Esqueci minha senha',
  [tokens.nav.general]: 'Geral',
  [tokens.nav.globalMenu]: 'Cardápio Global',
  [tokens.nav.globalProduct]: 'Produto Global',
  [tokens.nav.inactiveOrders]: 'Pedidos Encerrados',
  [tokens.nav.inactiveOrdersList]: 'Lista de Pedidos Encerrados',
  [tokens.nav.inventory]: 'Estoque',
  [tokens.nav.inventoryControl]: 'Controle de Estoque',
  [tokens.nav.inventoryStatement]: 'Extrato do Estoque',
  [tokens.nav.invoice]: 'Fatura',
  [tokens.nav.invoiceDetails]: 'Detalhes da Fatura',
  [tokens.nav.invoices]: 'Faturas',
  [tokens.nav.invoiceList]: 'Lista de Faturas',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'Lista',
  [tokens.nav.login]: 'Entrar',
  [tokens.nav.logistics]: 'Logística',
  [tokens.nav.logout]: 'Sair',
  [tokens.nav.mail]: 'Correio',
  [tokens.nav.management]: 'Gestão',
  [tokens.nav.menu]: 'Cardápio',
  [tokens.nav.menus]: 'Cardápios',
  [tokens.nav.notifications]: `Notificações`,
  [tokens.nav.order]: 'Pedido',
  [tokens.nav.orders]: 'Pedidos',
  [tokens.nav.orderDetails]: 'Detalhes do Pedido',
  [tokens.nav.orderList]: 'Lista de Pedidos',
  [tokens.nav.overview]: 'Visão Geral',
  [tokens.nav.pages]: 'Páginas',
  [tokens.nav.partners]: 'Parceiro',
  [tokens.nav.postCreate]: 'Criar Post',
  [tokens.nav.postDetails]: 'Detalhes do Post',
  [tokens.nav.postList]: 'Lista de Post',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.product]: 'Produto',
  [tokens.nav.products]: 'Produtos',
  [tokens.nav.productList]: 'Lista de Produtos',
  [tokens.nav.profile]: 'Perfil',
  [tokens.nav.recoverPassword]: 'Recuperar senha',
  [tokens.nav.register]: 'Cadastrar',
  [tokens.nav.reports]: 'Relatórios',
  [tokens.nav.resetPassword]: 'Redefinir senha',
  [tokens.nav.role]: 'Perfil',
  [tokens.nav.roles]: 'Perfis',
  [tokens.nav.roleList]: 'Lista de Perfis',
  [tokens.nav.schedule]: 'Programação',
  [tokens.nav.security]: 'Segurança',
  [tokens.nav.settings]: `Configurações`,
  [tokens.nav.signUp]: `Inscrever`,
  [tokens.nav.socialMedia]: 'Midia Social',
  [tokens.nav.statement]: `Extrato`,
  [tokens.nav.stockEntry]: 'Entrada de estoque',
  [tokens.nav.supplier]: `Fornecedor`,
  [tokens.nav.privacyPolicy]: 'Política de Privacidade',
  [tokens.nav.terms]: 'Termos e Condições',
  [tokens.nav.verifyCode]: 'Verificar Código',
  [tokens.nav.verifyEmail]: 'Verificar E-mail',
  [tokens.nav.venue]: 'Estabelecimento',
  [tokens.nav.venues]: 'Estabelecimentos',
  [tokens.nav.venueList]: 'Lista de Estabelecimentos',
  [tokens.nav.venueScheduleTable]: 'Programação dos Estabelecimentos',
  [tokens.nav.user]: 'Usuário',
  [tokens.nav.users]: 'Usuários',
  [tokens.nav.userList]: 'Lista de Usuários',
  [tokens.pages.app.bannerHeader]: 'Bem-vindo de volta',
  [tokens.pages.app.bannerSubtitle]: `As promoções ativadas no último mês impulsionaram as suas vendas! Que tal cadastrar novas promoções?`,
  [tokens.pages.app.bannerCTA]: 'Cadastrar promoções',
  [tokens.pages.globalMenu.dialogSubtitle]: 'Selecione os produtos para adicionar ao cardápio do estabelecimento',
  [tokens.pages.invoice.codeDialogHeader]: 'Digite o código de validação de entrega',
  [tokens.pages.invoice.codeDialogText]: 'Por favor solicite ao cliente o código de validação de 4 dígitos',
  [tokens.pages.invoice.leftFootnote]: 'Este comprovante não tem validade fiscal.',
  [tokens.pages.invoice.reminderDialogAlert] : 'O cliente será notificado que o seu pedido está',
  [tokens.pages.invoice.reminderDialogHeader]: 'Lembrar Cliente',
  [tokens.pages.invoice.reminderDialogSubtitle]: 'Podemos notificar o cliente mais uma vez caso o pedido ainda não tenha sido retirado.',
  [tokens.pages.invoice.reminderDialogText]: 'Gostaria de continuar?',
  [tokens.pages.invoice.rightFootnote]: 'suporte@checktech.app',
  [tokens.pages.invoice.updateDialogAlert]: 'O cliente será notificado da',
  [tokens.pages.invoice.updateDialogAlertStrong]: 'situação atualizada do pedido',
  [tokens.pages.invoice.updateDialogHeader]: 'Atualizar a Situação do Pedido',
  [tokens.pages.invoice.updateDialogText]: 'Por favor confirme a alteração para a próxima situação',
  [tokens.pages.login.formHeader]: 'Entrar no Check',
  [tokens.pages.login.formSubtitle]: 'Entre com os seus dados',
  [tokens.pages.login.noAccountText]: 'Não possui uma conta?',
  [tokens.pages.login.sideBanner]: 'Olá, bem-vindo novamente!',
  [tokens.pages.product.inventoryDescription]: 'Defina o estoque mínimo do produto, assim te avisamos quando o estoque estiver baixo',
  [tokens.pages.product.switchDescription]: 'Mantenha desativado para {{ action }} este produto sem afetar o cardápio dos estabelecimentos',
  [tokens.pages.register.formHeader]: 'Cadastrar no Check',
  [tokens.pages.register.formSubtitle]: 'Seu novo sistema cashless e cardless',
  [tokens.pages.register.formFooterStart]: 'Ao cadastrar, confirmo ter lido e estar de acordo com os',
  [tokens.pages.register.formFooterEnd]: 'do Check',
  [tokens.pages.register.existingAccountText]: 'Já possui uma conta?',
  [tokens.pages.register.sideBanner]: 'Otimize a sua operação com o Check',
  [tokens.pages.signUp.formHeader]: 'Quer ter o Check no seu Estabelecimento?',
  [tokens.pages.signUp.formSubtitle]: 'Atualmente o acesso está restrito a parceiros convidados. Se inscreva abaixo na lista de espera. Entraremos em contato quando for hora de impulsionar a sua operação!',
  [tokens.pages.signUp.formFooterStart]: 'Ao inscrever, confirmo ter lido e estar de acordo com os',
  [tokens.pages.signUp.formFooterEnd]: 'do Check',
  [tokens.pages.signUp.existingAccountText]: 'Já possui uma conta?',
  [tokens.pages.signUp.sideBanner]: 'Seja um Parceiro do Check!',
  [tokens.pages.resetPwrd.formHeader]: 'Esqueceu a sua senha?',
  [tokens.pages.resetPwrd.formSubtitle]: 'Por favor digite o e-mail associado a sua conta que enviaremos um link para redefinir a sua senha.',
  [tokens.pages.schedule.formAlert]: 'O estabelecimento irá abrir e fechar automaticamente para o público de acordo com esta configuração. Você poderá alterar manualmente o funcionamento na aba',
  [tokens.pages.schedule.formHeader]: 'Detalhes da Grade Horária',
  [tokens.pages.schedule.formSubtitle]: 'Defina a programação de funcionamento e atendimento ao público.',
  [tokens.pages.schedule.closesAt]: 'Fecha às',
  [tokens.pages.schedule.openUntil]: 'Aberto até às',
  [tokens.pages.schedule.opensAt]: 'Abre às',
  [tokens.pages.userProfile.general.scheduleSwitch]: 'Sincronizar com a programação de funcionamento do estabelecimento',
  [tokens.pages.userProfile.general.scheduleSwitchTooltip]: 'O acesso do usuário ficará desabilitado fora do horário de funcionamento',
  [tokens.pages.userProfile.general.scheduleSwitchDisabledTooltip]: 'Não é possível sincronizar este perfil de usuário',
  [tokens.pages.userProfile.general.statusSwitch]: 'Inativar o acesso do usuário à plataforma',
  [tokens.pages.userProfile.general.statusSwitchDisabledTooltip]: 'Não é possível inativar o seu próprio acesso',
  [tokens.pages.userProfile.general.roleDialogHeader]: 'Permissões do Perfil {{ role }}',
  [tokens.pages.userProfile.general.roleDialogSubtitle]: '',
  [tokens.pages.userProfile.security.altHeader]: 'Não é possível alterar o seu próprio acesso aos estabelecimentos',
  [tokens.pages.userProfile.security.header]: 'Configure quais estabelecimentos o {{name}} pode acessar',
  [tokens.pages.userProfile.security.listAltHeader]: 'Estabelecimentos Autorizados',
  [tokens.pages.userProfile.security.listHeader]: 'Estabelecimentos Não Autorizados',
  [tokens.pages.venues.noData]: `Nenhum estabelecimento encontrado`,
  [tokens.pages.venues.noDataTitle]: `Comece cadastrando o seu primeiro!`,
  [tokens.pages.venues.bulkEditDialogHeader]: 'Alterar Estabelecimentos Selecionados',
  [tokens.pages.venues.bulkEditDialogSubtitle]: 'Defina quais campos você quer editar para todas os estabelecimentos selecionados e defina o valor desejado.',
  [tokens.pages.venueProfile.docs.header]: 'Documentação para verificação',
  [tokens.pages.venueProfile.docs.subtitle]: 'Envie a documentação necessária para verificação do estabelecimento',
  [tokens.pages.venueProfile.fiscal.header]: 'Dados Fiscais',
  [tokens.pages.venueProfile.fiscal.addressCheck]: 'Engereço de Registro',
  [tokens.pages.venueProfile.fiscal.addressCheckSub]: 'Utilizar o mesmo que o endereço de funcionamento',
  [tokens.pages.venueProfile.general.header]: 'Dados Básicos',
  [tokens.pages.venueProfile.general.openstatusSwitch]: 'Abrir manualmente o estabelecimento para o público',
  [tokens.pages.venueProfile.general.openstatusSwitchTooltip]: 'Quando aberto, o público pode fazer check-in e enviar pedidos',
  [tokens.pages.venueProfile.general.statusSwitch]: 'Aplique para deixar este estabelecimento visível ao público',
  [tokens.pages.venueProfile.general.statusSwitchTooltip]: 'Quando ativo, o estabelecimento fica visível ao público',
  [tokens.pages.venueProfile.general.statusSwitchAltTooltip]: 'Apenas estabelecimentos verificados podem ser visíveis ao público',
  [tokens.pages.venueProfile.general.settingsHeader]: 'Configurações Básicas',
  [tokens.pages.venueProfile.general.entranceFeeSub]: 'Valor pago para entrar no estabelecimento (padrão R$ {{ value }})',
  [tokens.pages.venueProfile.general.minAmountSub]: 'Valor mínimo do pedido (padrão R$ {{ value }})',
  [tokens.pages.venueProfile.menu.productDialog.subtitle]: 'Defina um preço normal, preço promocional e quantidade em estoque específicos para este estabelecimento',
  [tokens.pages.venueProfile.inventory.entryDialog.subtitle]: 'Registro de entrada de produto em estoque',
  [tokens.pages.venueProfile.inventory.historyDialog.subtitle]: 'Histórico de entrada e saída de estoque por',
  [tokens.pages.venueProfile.security.altHeader]: 'Não é possível alterar o seu próprio acesso aos estabelecimentos',
  [tokens.pages.venueProfile.security.header]: 'Configure o acesso de usuários a este estabelecimento',
  [tokens.pages.venueProfile.security.listAltHeader]: 'Usuários Autorizados',
  [tokens.pages.venueProfile.security.listHeader]: 'Usuários Não Autorizados',
  [tokens.pages.verifyCode.formHeader]: 'Código enviado com sucesso!',
  [tokens.pages.verifyCode.formSubtitleStart]: 'Enviamos um código de 6 dígitos para o seu e-mail.',
  [tokens.pages.verifyCode.formSubtitleEnd]: 'Digite o código no campo abaixo para redefinir sua senha.',
  [tokens.pages.verifyCode.formFooter]: 'Não recebeu o código?',
  [tokens.pages.verifiedEmail.header]: 'E-mail verificado com sucesso!',
  [tokens.popover.tenant.header]: 'Gestão Focada',
  [tokens.popover.tenant.subtitle]: 'Selecione um estabelecimento para ver o seu ambiente',
  [tokens.popover.tenant.generalTitle]: 'Gestão Global',
  [tokens.roles.admin]: 'Administrador',
  [tokens.roles.bartender]: 'Bartender',
  [tokens.roles.cook]: 'Cozinheiro',
  [tokens.roles.investor]: 'Investidor',
  [tokens.roles.manager]: 'Gerente',
  [tokens.snackbar.codeSent.error]: 'Erro ao enviar o código',
  [tokens.snackbar.codeSent.success]: 'Código enviado com sucesso!',
  [tokens.snackbar.codeValidation.error]: 'Erro ao validar o código',
  [tokens.snackbar.codeValidation.success]: 'Código validado com sucesso!',
  [tokens.snackbar.create.error]: 'Erro ao criar',
  [tokens.snackbar.create.success]: 'Criado com sucesso!',
  [tokens.snackbar.delete.error]: 'Erro ao excluir',
  [tokens.snackbar.delete.success]: 'Excluído com sucesso!',
  [tokens.snackbar.notification.error]: 'Erro ao enviar a notificação',
  [tokens.snackbar.notification.success]: 'Notificação enviada com sucesso!',
  [tokens.snackbar.passwordChange.error]: 'Erro ao alterar a senha',
  [tokens.snackbar.passwordChange.success]: 'Senha alterada com sucesso!',
  [tokens.snackbar.resetPassword.error]: 'Erro ao recuperar a senha',
  [tokens.snackbar.resetPassword.success]: 'Senha recuperada com sucesso!',
  [tokens.snackbar.resetPasswordEmail.error]: 'Erro ao enviar e-mail de recuperação de senha',
  [tokens.snackbar.resetPasswordEmail.success]: 'Recuperação de senha enviado com sucesso para {{ email }}',
  [tokens.snackbar.signUp.error]: 'Erro ao se inscrever',
  [tokens.snackbar.signUp.success]: 'Inscrição feita com sucesso!',
  [tokens.snackbar.update.error]: 'Erro ao atualizar os dados',
  [tokens.snackbar.update.success]: 'Dados atualizados com sucesso!',
  [tokens.snackbar.verifyEmail.error]: 'Erro ao enviar verificação de e-mail',
  [tokens.snackbar.verifyEmail.success]: 'Verificação de e-mail enviada com sucesso para {{ email }}',
  [tokens.status.label.active]: 'Ativo',
  [tokens.status.label.analyzing]: 'Em análise',
  [tokens.status.label.approved]: 'Aprovado',
  [tokens.status.label.canceled]: 'Cancelado',
  [tokens.status.label.closed]: 'Fechado',
  [tokens.status.label.completed]: 'Finalizado',
  [tokens.status.label.connected]: 'Conectado',
  [tokens.status.label.declined]: 'Recusado',
  [tokens.status.label.disconnected]: 'Desconectado',
  [tokens.status.label.inactive]: 'Inativo',
  [tokens.status.label.inStock]: 'Em Estoque',
  [tokens.status.label.lowStock]: 'Estoque Baixo',
  [tokens.status.label.onSale]: 'Em promoção',
  [tokens.status.label.open]: 'Aberto',
  [tokens.status.label.outOfStock]: 'Fora de Estoque',
  [tokens.status.label.pending]: 'Pendente',
  [tokens.status.label.preparing]: 'Preparando',
  [tokens.status.label.ready]: 'Pronto para retirada',
  [tokens.status.label.received]: 'Recebido',
  [tokens.status.label.reconnecting]: 'Reconectando',
  [tokens.status.label.sent]: 'Enviado',
  [tokens.status.step.cancel]: 'Cancelar',
  [tokens.status.step.complete]: 'Encerrar',
  [tokens.status.step.finishPrep]: 'Finalizar preparo',
  [tokens.status.step.receive]: 'Receber',
  [tokens.status.step.startPrep]: 'Iniciar preparo',
  [tokens.status.stepAction.orderCanceled]: 'Pedido cancelado',
  [tokens.status.stepAction.orderReceived]: 'Pedido recebido',
  [tokens.status.stepAction.startedPreparation]: 'Início de preparação',
  [tokens.status.stepAction.finishedPreparation]: 'Término de preparação',
  [tokens.status.stepAction.delivered]: 'Entregue ao cliente',
  [tokens.tables.openVenues]: 'Estabelecimentos Abertos',
  [tokens.tables.productRanking]: 'Produtos Mais Vendidos',
  [tokens.tags.containsAlcohol]: 'Contém álcool',
  [tokens.tags.nonAlcoholic]: 'Não alcoólico',
  [tokens.tags.type]: 'Tipo',
  [tokens.venueTypes.bar]: 'Bar',
  [tokens.venueTypes.events]: 'Eventos',
  [tokens.venueTypes.nightClub]: 'Balada',
  [tokens.venueTypes.restaurant]: 'Restaurante',
  [tokens.weekdays.monday]: 'Segunda-feira',
  [tokens.weekdays.tuesday]: 'Terça-feira',
  [tokens.weekdays.wednesday]: 'Quarta-feira',
  [tokens.weekdays.thursday]: 'Quinta-feira',
  [tokens.weekdays.friday]: 'Sexta-feira',
  [tokens.weekdays.saturday]: 'Sábado',
  [tokens.weekdays.sunday]: 'Domingo',
};

export default br;
