import axios from 'axios';
import { PATH_AUTH } from '../routes/paths';
// config
import { HOST_API, API_KEY } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'x-api-key': API_KEY
  }
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      if (config.headers) {
        // Set the Authorization header with the access token
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  async (error) => {
    return error;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // console.log('error intercepted', error)
    const originalRequest = error.config;

    // If the error is due to an invalid access token
    if (error.response.status === 401 && error.response.data.error === 'Invalid access token') {
      try {
        // console.log('401 intercepted', error)
        // Send a request to the server to refresh the access token
        const response = await axiosInstance.post('/venueusers/refresh', {
          refreshToken: localStorage.getItem('refreshToken'),
        });
        const newAccessToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;

        // Store the new access token and refresh token in local storage
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        // Set the Authorization header with the new access token
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return await axiosInstance(originalRequest);
      } catch (error) {
        // If there's an error refreshing the access token, redirect the user to the login page
        alert('Token expired');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = PATH_AUTH.login;
      }

    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
